import(/* webpackMode: "eager" */ "/vercel/path0/node_modules/@next/third-parties/dist/google/ga.js");
;
import(/* webpackMode: "eager" */ "/vercel/path0/node_modules/@next/third-parties/dist/google/gtm.js");
;
import(/* webpackMode: "eager" */ "/vercel/path0/node_modules/@next/third-parties/dist/ThirdPartyScriptEmbed.js");
;
import(/* webpackMode: "eager", webpackExports: ["Analytics"] */ "/vercel/path0/node_modules/@vercel/analytics/dist/react/index.mjs");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/vercel/path0/node_modules/next-intl/dist/esm/navigation/shared/BaseLink.js");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/vercel/path0/node_modules/next-intl/dist/esm/navigation/shared/LegacyBaseLink.js");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/vercel/path0/node_modules/next-intl/dist/esm/shared/NextIntlClientProvider.js");
;
import(/* webpackMode: "eager" */ "/vercel/path0/node_modules/next/dist/client/image-component.js");
;
import(/* webpackMode: "eager" */ "/vercel/path0/node_modules/next/dist/client/script.js");
;
import(/* webpackMode: "eager" */ "/vercel/path0/node_modules/next/dist/shared/lib/lazy-dynamic/dynamic-bailout-to-csr.js");
;
import(/* webpackMode: "eager" */ "/vercel/path0/node_modules/next/dist/shared/lib/lazy-dynamic/preload-chunks.js");
;
import(/* webpackMode: "eager" */ "/vercel/path0/node_modules/next/font/google/target.css?{\"path\":\"src/app/fonts/fonts.js\",\"import\":\"Inter\",\"arguments\":[{\"subsets\":[\"latin\"]}],\"variableName\":\"inter\"}");
;
import(/* webpackMode: "eager" */ "/vercel/path0/node_modules/next/font/google/target.css?{\"path\":\"src/app/fonts/fonts.js\",\"import\":\"Roboto_Mono\",\"arguments\":[{\"subsets\":[\"latin\"],\"display\":\"swap\"}],\"variableName\":\"roboto_mono\"}");
;
import(/* webpackMode: "eager" */ "/vercel/path0/node_modules/next/font/google/target.css?{\"path\":\"src/app/fonts/fonts.js\",\"import\":\"Jost\",\"arguments\":[{\"subsets\":[\"latin\"],\"display\":\"swap\"}],\"variableName\":\"jost\"}");
;
import(/* webpackMode: "eager" */ "/vercel/path0/public/clutupdatednew.webp");
;
import(/* webpackMode: "eager" */ "/vercel/path0/public/dungsnew.webp");
;
import(/* webpackMode: "eager" */ "/vercel/path0/public/ESOMAR_member_RGB-17.webp");
;
import(/* webpackMode: "eager" */ "/vercel/path0/public/footerImg_payments_2.webp");
;
import(/* webpackMode: "eager" */ "/vercel/path0/public/iso-27001-updated.webp");
;
import(/* webpackMode: "eager" */ "/vercel/path0/public/iso-9001-updated.webp");
;
import(/* webpackMode: "eager" */ "/vercel/path0/public/topupatednew.webp");
;
import(/* webpackMode: "eager" */ "/vercel/path0/src/app/globals.css");
;
import(/* webpackMode: "eager" */ "/vercel/path0/src/components/footer/footer.scss");
;
import(/* webpackMode: "eager" */ "/vercel/path0/src/app/[locale]/latest-insights/industry-reports.scss");
;
import(/* webpackMode: "eager" */ "/vercel/path0/src/components/navbar/navabar.jsx");
;
import(/* webpackMode: "eager" */ "/vercel/path0/src/components/top-header/top-header.jsx");
