'use client';

import { HiOutlineMagnifyingGlass } from 'react-icons/hi2';
import Image from 'next/image';
import { Link } from '@/i18n/routing';
import './searchBar.scss';
import { useEffect, useRef, useState } from 'react';
import { fetchReportsWithKeyword } from '@/app/utils/fetchdata';
import coherentmi_icon from '@/app/favicon.ico';
import { useLocale, useTranslations } from 'next-intl';
import { removeSpacesFromKeyword } from '@/app/utils/helpers';

export default function SearchBar({ show, setShow }) {
  const [searchResult, setSearchResult] = useState([]);
  const [keyword, setKeyword] = useState('');
  const [isEnKeyword, setIsEnKeyword] = useState(true);
  const inputRef = useRef(null);
  const locale = useLocale();
  const t = useTranslations('searchBar');

  function handleSearch(e) {
    setKeyword(e.target.value);
    const isEng = containsEnglishCharacters(e.target.value);
    if (isEng) {
      setIsEnKeyword(true);
    }
  }

  useEffect(() => {
    inputRef.current.focus();
    inputRef.current.select();
    inputRef.current.value = '';
    setSearchResult([]);
  }, [show]);

  useEffect(() => {
    const timeoutId = setTimeout(async () => {
      if (keyword !== '') {
        const result = await fetchReportsWithKeyword(keyword, locale);
        setSearchResult(result);
      }
    }, 300);
    return () => clearTimeout(timeoutId);
  }, [keyword, locale]);

  let inputPlace = 'Search Reports...';

  if (locale === 'ja') {
    inputPlace = '検索レポート...';
  } else if (locale === 'ko') {
    inputPlace = '보고서 검색...';
  }

  function containsEnglishCharacters(input) {
    // Regex to match uppercase and lowercase English letters
    const englishRegex = /[a-zA-Z]/;
    return englishRegex.test(input);
  }

  // //For the Search Bar
  const EmptyComponent = () => {
    return (
      <>
        <div className="mx-auto text-center my-3">
          <h5 className="font-bold">{t('heading')}</h5>
          <p className={'my-2'}>
            {t('p1')}{' '}
            <Link href={'/contact-us'} className="text-blue-500 font-semibold">
              {t('contactLinkText')}
            </Link>{' '}
            {t('p2')}
          </p>
        </div>
      </>
    );
  };

  return (
    <>
      <div
        className={show ? 'searchModal' : 'hidden'}
        onClick={() => {
          setShow(false);
          setSearchResult([]);
        }}
      >
        <div className="container">
          <div className="searchWrapper">
            <div className="searchInput " onClick={(e) => e.stopPropagation()}>
              <div>
                <button
                  className="btn btn-close-search my-2 my-sm-0 search-open"
                  title="Search"
                  onClick={setShow}
                >
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="14"
                    height="14"
                    fill="currentColor"
                    className="bi bi-x-lg"
                    viewBox="0 0 16 16"
                  >
                    <path d="M2.146 2.854a.5.5 0 1 1 .708-.708L8 7.293l5.146-5.147a.5.5 0 0 1 .708.708L8.707 8l5.147 5.146a.5.5 0 0 1-.708.708L8 8.707l-5.146 5.147a.5.5 0 0 1-.708-.708L7.293 8z"></path>
                  </svg>
                </button>
              </div>
              <span className="left-pan">
                <HiOutlineMagnifyingGlass size={20} />
              </span>
              <input
                id="keyword"
                type="search"
                onChange={handleSearch}
                ref={inputRef}
                autoComplete="off"
                placeholder={inputPlace}
                className="p-2 w-full mx-2"
              />
            </div>
            <ul className="searchResultList">
              {searchResult.length !== 0 ? (
                searchResult.map((item) => {
                  let link = `/industry-reports/${item.CustomName}`;
                  let key = item.newsId;

                  if (locale === 'ja') {
                    key = item.reportid;
                    link = `/industry-reports/${removeSpacesFromKeyword(
                      item.jaKeyword
                    )}-${item.reportid}`;
                  }

                  if (locale === 'ko') {
                    key = item.reportid;
                    link = `/industry-reports/${removeSpacesFromKeyword(
                      item.koKeyword
                    )}-${item.reportid}`;
                  }

                  return (
                    <li
                      key={key}
                      className="flex flex-row gap-3 items-center text-center"
                    >
                      {' '}
                      <Image
                        src={coherentmi_icon}
                        height={30}
                        width={30}
                        alt="coherentmi_icon"
                      />
                      <Link href={link} className="w-full text-start">
                        {item.keyword}
                      </Link>
                    </li>
                  );
                })
              ) : keyword && searchResult.length === 0 ? (
                <EmptyComponent />
              ) : (
                ''
              )}
            </ul>
          </div>
        </div>
      </div>{' '}
    </>
  );
}
