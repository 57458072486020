export { getCustomNamesNews } from 'next-flight-server-reference-proxy-loader?id=0003e9d2c7250481574f10837099086e262ac7e4d6&name=getCustomNamesNews!'
export { getCustomNamesBlogs } from 'next-flight-server-reference-proxy-loader?id=0084e9c046fd93439d76af10b8ec1a492963b1ab65&name=getCustomNamesBlogs!'
export { getCountries } from 'next-flight-server-reference-proxy-loader?id=00944e9c7451d023541e0d3b69686566cce1ab04a7&name=getCountries!'
export { fetchKeywords } from 'next-flight-server-reference-proxy-loader?id=00d75819b3ec1ac8597f0fcf8090dc736ecd42a326&name=fetchKeywords!'
export { getReportWithKeyword } from 'next-flight-server-reference-proxy-loader?id=40602ef0e28b36fc5c2dadb48d7966f51906b3675d&name=getReportWithKeyword!'
export { fetchReportForBuyNow } from 'next-flight-server-reference-proxy-loader?id=4087961773cd3f9e1226d4a3bb0b74a7d719f4e61c&name=fetchReportForBuyNow!'
export { getCustomNamesForRegional } from 'next-flight-server-reference-proxy-loader?id=40e0396ade3beda93e627e2af85ca6a8c66d329ec7&name=getCustomNamesForRegional!'
export { fetchTestimonials } from 'next-flight-server-reference-proxy-loader?id=40e6f97c6d637d5d1f23548ee454af368c51eb67f9&name=fetchTestimonials!'
export { fetchSingleReport } from 'next-flight-server-reference-proxy-loader?id=40ea5751adb1953352d35448d31bd85511f1fe872f&name=fetchSingleReport!'
export { getCustomNames } from 'next-flight-server-reference-proxy-loader?id=40eeab0b69feb0c6c5dc9e1166a1beed8b078bb12f&name=getCustomNames!'
export { fetchBestSellingReports } from 'next-flight-server-reference-proxy-loader?id=40f5edaae3099b30b8b217482692ee23df370dcfaf&name=fetchBestSellingReports!'
export { fetchMarketTrends } from 'next-flight-server-reference-proxy-loader?id=600a1bbbae05ab64a5fcfc481cb29d37b14d1acadc&name=fetchMarketTrends!'
export { fetchMarketShare } from 'next-flight-server-reference-proxy-loader?id=6013289941b1a15c1a9afbb942f1d33972fd77b750&name=fetchMarketShare!'
export { fetchCustomNameFromReportId } from 'next-flight-server-reference-proxy-loader?id=60328c31b342f67cfc69a9d84794b2238c505a6b76&name=fetchCustomNameFromReportId!'
export { fetchFourReportsOfIndustry } from 'next-flight-server-reference-proxy-loader?id=603aea95e6d408a5ec7508d135c444f55f2e50902a&name=fetchFourReportsOfIndustry!'
export { fetchIndustryMetaData } from 'next-flight-server-reference-proxy-loader?id=606205496e00873443b84bb976f3b0709bf0447557&name=fetchIndustryMetaData!'
export { fetchTranslatedReport } from 'next-flight-server-reference-proxy-loader?id=60670d218bea0307ba4ff45dbfe65a5888521b80ba&name=fetchTranslatedReport!'
export { fetchMarketSize } from 'next-flight-server-reference-proxy-loader?id=607406c629f863998d23a0637b867681881aad67ee&name=fetchMarketSize!'
export { fetchReportMetaData } from 'next-flight-server-reference-proxy-loader?id=6096c3611559bb08c6cb090121bd5dbe0811e1b49c&name=fetchReportMetaData!'
export { fetchReportMetaDataForRegional } from 'next-flight-server-reference-proxy-loader?id=609d5d9a77709b498705c77ab7879a6172e1722d7d&name=fetchReportMetaDataForRegional!'
export { fetchReportsWithKeyword } from 'next-flight-server-reference-proxy-loader?id=60ae2280520be0baaec37a8dd7c65c3309c2bdc2b9&name=fetchReportsWithKeyword!'
export { fetchFAQs } from 'next-flight-server-reference-proxy-loader?id=60ba20c7ca8dd4a8dcf44d1ca7a67b39a294f3bf28&name=fetchFAQs!'
export { fetchReportCount } from 'next-flight-server-reference-proxy-loader?id=60ca4db6f0a22b72430c2a9c6131d0fa6552e90020&name=fetchReportCount!'
export { isReportTranslated } from 'next-flight-server-reference-proxy-loader?id=60d4fca1a3726e760e99080b2f88166c582fdce7b0&name=isReportTranslated!'
export { fetchCompanies } from 'next-flight-server-reference-proxy-loader?id=60f3d24bb838f6b8ed20e1a95c725bc474fe153868&name=fetchCompanies!'
export { fetchRegionalKeyword } from 'next-flight-server-reference-proxy-loader?id=7019d7528eb348afbc5459d2dbfc47ad422c1a3a03&name=fetchRegionalKeyword!'
export { fetchRelatedReports } from 'next-flight-server-reference-proxy-loader?id=707561a6daa5e2ed1683770666066e8471302acb06&name=fetchRelatedReports!'
export { fetchIndustryReports } from 'next-flight-server-reference-proxy-loader?id=7f77e1881162c4db9980a4bffa24df7bae850a40b6&name=fetchIndustryReports!'
export { fetchPublishedReports } from 'next-flight-server-reference-proxy-loader?id=7f8e7a2b3508cc799f269175789f2620b088231314&name=fetchPublishedReports!'
export { fetchIndustries } from 'next-flight-server-reference-proxy-loader?id=7fb1c61ff2dfa2d2468fa4b92276a401b03f02a5bb&name=fetchIndustries!'